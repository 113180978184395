import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import WebSocketService from "../../services/WebSocketService";
import BlockAreaService from "../../services/BlockAreaService";
import {showNotification} from "./notificationSlice";

export const fetchScooterData = createAsyncThunk('map/fetchScooters', async (_, { rejectWithValue }) => {
    try {
        return await new Promise((resolve, reject) => {
            let timeoutId;
            WebSocketService.connect();

            WebSocketService.onScooters((msg) => {
                clearTimeout(timeoutId);
                resolve(msg);
                WebSocketService.disconnect();
            });

            timeoutId = setTimeout(() => {
                WebSocketService.disconnect();
                reject(rejectWithValue('WebSocket Timeout'));
            }, 60000);

        });
    } catch (error) {
        return rejectWithValue('WebSocket Error');
    } finally {
        WebSocketService.disconnect();
    }
});

export const getAllBlockedArea = createAsyncThunk('map/getAllBlockedArea', async (_, { rejectWithValue  }) => {
    try {
        const response = await BlockAreaService.getAllBlockedArea();
        if (response.status === 200) {
            const filteredBlockedAreas = response.data.data.filter(area => area.isEnter === false);

            return {
                blockedArea: filteredBlockedAreas
            };
        } else {
            return rejectWithValue('Failed to getAllBlockedArea');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const addBlockedArea = createAsyncThunk('map/addBlockedArea', async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await BlockAreaService.addBlockedArea(data);
        if (response.status === 201) {
            dispatch(showNotification({
                message: 'Başarılı',
                description: 'Başarıyla oluşturuldu',
                type: 'success',
                key: 'unique_key_success'
            }));
            return response.data;
        } else {
            dispatch(showNotification({
                message: 'Error',
                description: 'Failed to add blocked area.',
                type: 'error',
                key: 'unique_key_error'
            }));
            return rejectWithValue('Failed to addBlockedArea');
        }
    } catch (error) {
        dispatch(showNotification({
            message: 'Error',
            description: error.response?.data?.message || 'Network Error',
            type: 'error',
            key: 'unique_key_error'
        }));
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const deleteBlockedArea = createAsyncThunk('map/deleteBlockedArea', async (id, { rejectWithValue  }) => {
    try {
        const response = await BlockAreaService.deleteBlockedArea(id);
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            return id
        } else {
            return rejectWithValue('Failed to addBlockedArea');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});


export const getAllNoEntryArea = createAsyncThunk('map/getAllNoEntryArea', async (_, { rejectWithValue  }) => {
    try {
        const response = await BlockAreaService.getAllBlockedArea();
        if (response.status === 200) {
            const filteredNoEntryArea = response.data.data.filter(area => area.isEnter === true);

            return {
                noEntryArea: filteredNoEntryArea
            };
        } else {
            return rejectWithValue('Failed to getAllBlockedArea');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const addNoEntryArea = createAsyncThunk('map/addNoEntryArea', async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await BlockAreaService.addNoEntryArea(data);
        if (response.status === 201) {
            dispatch(showNotification({
                message: 'Başarılı',
                description: 'Başarıyla oluşturuldu',
                type: 'success',
                key: 'unique_key_success'
            }));
            return response.data;
        } else {
            dispatch(showNotification({
                message: 'Error',
                description: 'Failed to add blocked area.',
                type: 'error',
                key: 'unique_key_error'
            }));
            return rejectWithValue('Failed to addNoEntryArea');
        }
    } catch (error) {
        dispatch(showNotification({
            message: 'Error',
            description: error.response?.data?.message || 'Network Error',
            type: 'error',
            key: 'unique_key_error'
        }));
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});


export const mapSlice = createSlice({
    name: 'map',
    initialState: {
        map: null,
        loading: false,
        error: null,
        isOpenSidebar: false,
        isOpenBlockedArea: false,
        isOpenNoEntryArea: false,
        isOpenMapSearch: false,
        selectedScooter: null,
        scooters: [],
        drawnAreas: [],
        showDrawnAreas: false,
        blockedArea: [],
        noEntryArea: []
    },
    reducers: {
        initializeMap: (state, action) => {
            state.map = action.payload;
        },
        updateScooters: (state, action) => {
            state.scooters = action.payload;
        },
        toggleSidebar: (state, action) => {
            state.isOpenSidebar = action.payload;
        },
        toggleBlockedArea: (state, action) => {
            state.isOpenBlockedArea = action.payload;
            if (state.isOpenMapSearch) {
                state.isOpenMapSearch = false;
            }
            if (state.isOpenSidebar) {
                state.isOpenSidebar = false;
            }
            if (state.isOpenNoEntryArea) {
                state.isOpenNoEntryArea = false;
            }
        },
        toggleNoEntryArea: (state, action) => {
            state.isOpenNoEntryArea = action.payload;
            if (state.isOpenMapSearch) {
                state.isOpenMapSearch = false;
            }
            if (state.isOpenSidebar) {
                state.isOpenSidebar = false;
            }
            if (state.isOpenBlockedArea) {
                state.isOpenBlockedArea = false;
            }
        },
        toggleMapSearch: (state, action) => {
            state.isOpenMapSearch = action.payload;
            if (state.isOpenBlockedArea) {
                state.isOpenBlockedArea = false;
            }
            if (state.isOpenSidebar) {
                state.isOpenSidebar = false;
            }
        },
        setSelectedScooter: (state, action) => {
            state.selectedScooter = action.payload;
        },
        setScooters: (state, action) => {
            state.scooters = action.payload;
        },
        addScooter: (state, action) => {
            const newScooter = action.payload;
            const index = state.scooters.findIndex(scooter => scooter.id === newScooter.id);
            if (index > -1) {
                state.scooters[index] = newScooter;
            } else {
                state.scooters.push(newScooter);
            }
        },
        filterScooters: (state) => {
            if (state.selectedScooter) {
                state.scooters = state.scooters.filter(scooter => scooter.id === state.selectedScooter.id);
            }
        },
        addDrawnArea: (state, action) => {
            state.drawnAreas.push(action.payload);
        },
        toggleShowDrawnAreas: (state, action) => {
            state.showDrawnAreas = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchScooterData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchScooterData.fulfilled, (state, action) => {
                state.loading = false;
                state.scooters = action.payload;
            })
            .addCase(fetchScooterData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                WebSocketService.disconnect();
            })
            .addCase(getAllBlockedArea.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllBlockedArea.fulfilled, (state, action) => {
                state.blockedArea = action.payload.blockedArea;
                state.loading = false;
            })
            .addCase(getAllBlockedArea.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(addBlockedArea.pending, (state) => {
                state.loading = true;
            })
            .addCase(addBlockedArea.fulfilled, (state, action) => {
                state.blockedArea.push(action.payload.data);
                state.loading = false;
            })
            .addCase(addBlockedArea.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(deleteBlockedArea.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteBlockedArea.fulfilled, (state, action) => {
                state.blockedArea = state.blockedArea.filter(model => model.id !== action.payload)
                state.loading = false
            })
            .addCase(deleteBlockedArea.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getAllNoEntryArea.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllNoEntryArea.fulfilled, (state, action) => {
                state.noEntryArea = action.payload.noEntryArea;
                state.loading = false;
            })
            .addCase(getAllNoEntryArea.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(addNoEntryArea.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNoEntryArea.fulfilled, (state, action) => {
                state.noEntryArea.push(action.payload.data);
                state.loading = false;
            })
            .addCase(addNoEntryArea.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    },
});

export const {
    toggleSidebar,
    toggleBlockedArea,
    toggleNoEntryArea,
    setSelectedScooter,
    addScooter,
    setScooters,
    filterScooters,
    initializeMap,
    updateScooters,
    addDrawnArea,
    toggleShowDrawnAreas,
    toggleMapSearch,
} = mapSlice.actions;

export default mapSlice.reducer;
