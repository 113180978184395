import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { notification } from 'antd';
import NotificationService from "../../services/NotificationService";

export const getAllNotification = createAsyncThunk('notification/getAllNotification', async ({ order, page, take }, { rejectWithValue }) => {
    try {
        const response = await NotificationService.getAllNotification(order, page, take);
        if (response.status === 200) {
            return {
                notifications: response.data.data,
                totalNotification: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
            };
        } else {
            return rejectWithValue('Failed to getScooterDetailFault');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const addNotification = createAsyncThunk('notification/addNotification', async (data, { rejectWithValue }) => {
    try {
        const response = await NotificationService.addNotification(data);
        if (response.status === 200 || response.status === 201) {
            return response.data.data;
        } else {
            return rejectWithValue('Failed to addNotification');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        totalNotification: 0,
        page: 1,
        take: 10,
        loading: false,
        error: null,
        notifications: [],
        userNotifications: []
    },
    reducers: {
        showNotification: (state, action) => {
            const { message, description, type, duration, key } = action.payload;

            notification[type]({
                message,
                description,
                duration: duration || 4.5,
                key,
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllNotification.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllNotification.fulfilled, (state, action) => {
                state.notifications = action.payload.notifications;
                state.totalNotification = action.payload.totalNotification;
                state.page = action.payload.page;
                state.take = action.payload.take;
                state.loading = false;
            })
            .addCase(getAllNotification.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(addNotification.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNotification.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    state.notifications.push(action.payload);
                }
            })
            .addCase(addNotification.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
});

export const { showNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
